@keyframes skeleanime {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(200%);
  }
}


.loading {
    &__hidden {
        display: none;
    }
    &__line{
        position: relative;
        overflow: hidden;
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(#efefef,0.6);
        border-radius: 3px;
        margin-top: 10px;
        margin-bottom: 20px;
        
        &::after{
            display: block;
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, transparent, rgba(255,255,255,.4));
            animation: skeleanime 1.5s infinite ;
        }
    }
}

.nav-sidemenu,
.news-release-list,
.archivelist_wrapper {
    .loading__line {
        height: 1.3em;
        margin: 0;
    }
    .info-icon:has(> .loading__line){
        background-color: #dedede;
        // height: 1em;
        padding: 0;
    }
}

.c-skeleton {
    
    &--footer-list{

    // c-skeleton--footer-list
        li {
            height: 1.3rem;
            width: 90%;
            // 複数並んだときに自然な文字列が入りそうな長さにする
            &:nth-child(5n) {
                width: 80%;
            }
            &:nth-child(3n) {
                width: 30%;
            }
            &:nth-child(2n){
                width: 50%;
            }
        }
    }
}