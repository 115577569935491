$tag-colors :(
  normal: #898989,
  newsrelease: #00a088,
  ir:#2f8ef5,
  sustainability:#a2cf00,
  products:rgb(240, 106, 106),
  misc:rgb(243, 181, 88)
);

.info-icon,
.tecnology-report-icon {
	float: left;
	text-align: center;
	width: 65px;
	padding: 3px 0;
	background-color: map-get($tag-colors, normal);
	color: #fff;
	line-height: 1;
	margin-right: 5px;
  // margin-bottom: 5px;
	font-size: 1.2rem;
  border-radius: 3px 3px 3px 3px;
  @media print {
    border: solid 1px map-get($tag-colors, normal);
    color: #333;
  }
}

// タグの切り欠き (採用しない)
// .info-icon {
//   position: relative;
//   overflow: hidden;
//   &::after {
//     content: "";
//     display: block;
//     position: absolute;
//     top: -5px;
//     left: -5px;
//     width: 10px;
//     height: 10px;
//     transform: rotate(45deg);
//     background-color: white;
//   }
// }
.taglabel-newsrelease {
  background-color: map-get($tag-colors, newsrelease);
  border-radius: 1px;
  display: none;
  @media print {
      border-color: map-get($tag-colors, newsrelease);
      background-color: transparent;
  }
}


.taglabel-ir {
  background-color: map-get($tag-colors, ir);
  @media print {
      border-color: map-get($tag-colors, ir);
      background-color: transparent;
  }
}


.taglabel-sustainability {
  overflow: hidden;
  background-color: map-get($tag-colors, sustainability);
  letter-spacing: -1px;
  width: 100px;
  text-shadow: rgba(87, 160, 18, 0.842) 0 0 3px;
  @media print {
      border-color: darken($color: map-get($tag-colors, sustainability), $amount: .5);
      background-color: transparent;
      text-shadow: none;
  }
  &.taglabel-lang{
  &_en {
    letter-spacing: 0px;
    font-weight: bold;
  }
  &_cn {
    width: 65px;
  }
}
}
:lang(en) .taglabel-sustainability {
  letter-spacing: 0px;
  font-weight: bold;
}
.taglabel-products-biz {
  background-color: map-get($tag-colors, products);
  @media print {
      border-color: map-get($tag-colors, products);
      background-color: transparent;
  }
  &.taglabel-lang{
  &_en {
    width: 100px;
  }
}
}

.taglabel-misc {
  background-color: map-get($tag-colors, misc);
  @media print {
      border-color: map-get($tag-colors, misc);
      background-color: transparent;
  }
}
.tagsize {
  &_normal {
    width: 65px;
  }
  &_wide {
    width: 100px;
  }
}