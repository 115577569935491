
.about-banner {
  margin-top: 50px;
  margin-bottom: 50px;
}

.about-banner img {
  width: 100%;
}

.historicals {
  clear: both;
  padding: 20px 0;
}

.historicals .banner-top-shorai,
.historicals .banner-top-50years {
  width: 49%;
  display: block;
}

.historicals .banner-top-shorai {
  float: left;
}

.historicals .banner-top-50years {
  float: right;
}

.mai-visual {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  line-height: 0;
}

@media screen and (max-width: 767px) {
  .mai-visual {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}

.mai-visual a {
  text-decoration: none;
}

.for-mobile {
  display: none;
}

@media screen and (max-width: 767px) {
  .for-pc {
    display: none;
  }
  .for-mobile {
    display: block;
  }
}
// 
@import "_information_tagged_colors.scss";


// Object // Component

@import "./Object/Component/c-skeleton.scss";

